import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import BlogHeader from "../../components/Blog/BlogHeader"
import BlogPost from "../../components/BlogPost/BlogPost"
import Footer from "../../components/Footer/Footer"
import Layout from "../../components/Layout/Layout"
import NavbarContainer from "../../components/misc/NavbarContainer/NavbarContainer"
import useBlogList from "../../hooks/useBlogList"

function BlogPage() {
  const blogList = useBlogList()
  const data = useStaticQuery(graphql`{
  tapas: file(relativePath: {eq: "blog1_tapas.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  drink: file(relativePath: {eq: "blog1_drink.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  shrimp: file(relativePath: {eq: "blog1_shrimp.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
}
`)
  // eslint-disable-next-line no-unused-vars
  const { _, ...photos } = data
  const { title, time, article, postId } = blogList.find(
    blog => blog.postId === "cosy"
  )
  return (
    <ParallaxProvider>
      <Layout title="Blog | Be My Guest Vietnamilainen Ravintola | Jätkäsaari Helsinki">
        <NavbarContainer dark />
        <BlogHeader title={title} />
        <BlogPost
          time={time}
          article={article}
          photos={photos}
          postId={postId}
        />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default BlogPage
